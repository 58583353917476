@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "GothamNarrow-Light";
  src: url("app/assets/fonts/subset-GothamNarrow-Light.eot");
  /*src: local('Gotham Narrow Light'), local('GothamNarrow-Light'),*/
  src: url("app/assets/fonts/subset-GothamNarrow-Light.eot?#iefix")
      format("embedded-opentype"),
    url("app/assets/fonts/subset-GothamNarrow-Light.woff2") format("woff2"),
    url("app/assets/fonts/subset-GothamNarrow-Light.woff") format("woff"),
    url("app/assets/fonts/subset-GothamNarrow-Light.ttf") format("truetype");
  /*url('app/assets/fonts/subset-GothamNarrow-Light.svg#GothamNarrow-Light') format('svg');*/
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Medium";
  src: url("app/assets/fonts/subset-GothamNarrow-Medium.eot");
  /*src: local('Gotham Narrow Medium'), local('GothamNarrow-Medium'),*/
  src: url("app/assets/fonts/subset-GothamNarrow-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("app/assets/fonts/subset-GothamNarrow-Medium.woff2") format("woff2"),
    url("app/assets/fonts/subset-GothamNarrow-Medium.woff") format("woff"),
    url("app/assets/fonts/subset-GothamNarrow-Medium.ttf") format("truetype");
  /*url('app/assets/fonts/subset-GothamNarrow-Medium.svg#GothamNarrow-Medium') format('svg');*/
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Thin";
  src: url("app/assets/fonts/subset-GothamNarrow-Thin.eot");
  /*src: local('Gotham Narrow Thin'), local('GothamNarrow-Thin'),*/
  src: url("app/assets/fonts/subset-GothamNarrow-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("app/assets/fonts/subset-GothamNarrow-Thin.woff2") format("woff2"),
    url("app/assets/fonts/subset-GothamNarrow-Thin.woff") format("woff"),
    url("app/assets/fonts/subset-GothamNarrow-Thin.ttf") format("truetype");
  /*url('app/assets/fonts/subset-GothamNarrow-Thin.svg#GothamNarrow-Thin') format('svg');*/
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Book";
  src: url("app/assets/fonts/subset-GothamNarrow-Book.eot");
  /*src: local('Gotham Narrow Book'), local('GothamNarrow-Book'),*/
  src: url("app/assets/fonts/subset-GothamNarrow-Book.eot?#iefix")
      format("embedded-opentype"),
    url("app/assets/fonts/subset-GothamNarrow-Book.woff2") format("woff2"),
    url("app/assets/fonts/subset-GothamNarrow-Book.woff") format("woff"),
    url("app/assets/fonts/subset-GothamNarrow-Book.ttf") format("truetype");
  /*url('app/assets/fonts/subset-GothamNarrow-Book.svg#GothamNarrow-Book') format('svg');*/
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Bold";
  src: url("app/assets/fonts/subset-GothamNarrow-Bold.eot");
  /*src: local('GothamNarrow-Bold'), local('GothamNarrow-Bold'),*/
  src: url("app/assets/fonts/subset-GothamNarrow-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("app/assets/fonts/subset-GothamNarrow-Bold.woff2") format("woff2"),
    url("app/assets/fonts/subset-GothamNarrow-Bold.woff") format("woff"),
    url("app/assets/fonts/subset-GothamNarrow-Bold.ttf") format("truetype");
  /*url('app/assets/fonts/subset-GothamNarrow-Bold.svg#GothamNarrow-Bold') format('svg');*/
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transition: background 0.2s ease-in-out;
  font-family: "GothamNarrow-Book", "Helvetica Neue", sans-serif;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f2f7fd 100%);
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  position: relative;
}

* {
  outline: none;
}

*:not([data-text="true"]) {
  font-family: "GothamNarrow-Book", "Helvetica Neue", sans-serif;
}

.MuiTooltip-popperInteractive > div {
  padding: 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(152, 161, 170, 0.6);
}
#rb-dropdown-menu {
  z-index: 10;
}

#ruler {
  z-index: 0;
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
}

.MuiListItem-button.Mui-selected {
  color: #fff;
}

.MuiPaginationItem-page.Mui-selected {
  color: #fff;
}

b {
  font-family: "GothamNarrow-Bold", "Helvetica Neue", sans-serif !important;
}

a:hover {
  color: none;
}

button {
  appearance: none;
  -webkit-appearance: button-bevel;
}

input[type="text"] {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
}

/* Data themes table */

.data-themes-table-header-cell {
  color: #262c34;
  font-weight: 400;
  border-width: 1px;
  background: #dfe3e6;
  border-color: #98a1aa;
  border-style: solid solid solid none;
}

.data-themes-table-header-cell:first-of-type {
  border-style: solid;
}

.data-themes-table-id-cell {
  color: #868e96;
  border-width: 1px;
  text-align: center;
  background: #dfe3e6;
  border-color: #98a1aa;
  border-style: none solid solid solid;
}

.data-themes-table-cell {
  color: #262c34;
  background: #f1f3f5;
}

#inline-color-picker-popover {
  right: 26px !important;
}
.rhcpCard {
  animation-name: shadow-pulse;
  animation-duration: 3s;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(96, 97, 229, 0.2);
  }
  50% {
    box-shadow: 0 0 0 7px #dadaf8;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(96, 97, 229, 0.2);
  }
}
